export const utils = {
  getMedian: values => {
    const vals = values.sort((a, b) => a - b)
      .map(v => +v) // NOTE convert string to number
    // console.log('values', values)
    // console.log('vals', vals)
    const lowMiddle = Math.floor((vals.length - 1) / 2)
    // console.log('lowMiddle', lowMiddle)
    const highMiddle = Math.ceil((vals.length - 1) / 2)
    // console.log('highMiddle', highMiddle)
    const median = (vals[lowMiddle] + vals[highMiddle]) / 2
    // console.log('vals[lowMiddle]', vals[lowMiddle])
    // console.log('vals[highMiddle]', vals[highMiddle])
    // console.log('vals[lowMiddle] + vals[highMiddle]', vals[lowMiddle] + vals[highMiddle])
    return median
  },
  errorWhiskers: ({ left, mid, right, min, max })=>{
    return `
    M${left},${min}
    L${right},${min}
    M${mid},${min}
    L${mid},${max}
    M${left},${max}
    L${right},${max}`
  },
  circlePath: (cx, cy, r)=>{
    const path = 'M '+cx+' '+cy+' m -'+r+', 0 a '+r+','+r+' 0 1,0 '+(r*2)+',0 a '+r+','+r+' 0 1,0 -'+(r*2)+',0'
    console.log('path', path)
    return path
  },
  getErrorBars:({ data })=>{

    const errorWhiskerWidth = 0.1
    const radius = 15
    // const negRad = -radius
    // const posRad2 = radius * 2
    // const negRad2 = radius * 2

    return data.map((item, i) => {
      // console.log('item',item)
      // console.log('data[i]',data[i])
      const yVals = data[i].y
      // console.log('yVals',yVals)
      const min = Math.min(...yVals)
      const max = Math.max(...yVals)
      const median = utils.getMedian(yVals)
      console.log('median', median)
      // console.log('min', min)
      // console.log('max', max)


      const left = i - errorWhiskerWidth
      const right = left + (errorWhiskerWidth * 2)

      // console.log('left, right', left, right)
      const errorBarPath = `
      ${utils.errorWhiskers({ left, right, mid: i, min, max })}
      ${utils.circlePath(i, median, radius)}
      M${left},${median}
      L${right},${median}`

      console.log('errorBarPath',errorBarPath)
      const shape = {
        type: 'path',
        path: errorBarPath,
        // fillcolor: 'rgba(44, 160, 101, 0.5)',
        line: {
          color: 'rgba(0,0,0,1.0)'
        }
      }
      return shape
    })

  }
}
