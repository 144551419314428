import React from 'react'
// import logo from './logo.svg'
import { tsvParse } from 'd3-dsv'
import TextareaAutosize from 'react-textarea-autosize'
import { DebounceInput } from 'react-debounce-input'
// import { ResponsiveBar } from '@nivo/bar'
// import { ResponsiveScatterPlot } from '@nivo/scatterplot'
// import { ResponsiveSwarmPlot } from '@nivo/swarmplot'
// import { generateCountriesData, sets } from '@nivo/generators'
// import { PatternLines } from '@nivo/core'

import Plot from 'react-plotly.js'
import { utils } from './utils'
// import { dataString } from './data.js'
// import random from 'lodash/random'
import 'tachyons/css/tachyons.min.css' // import this from node_modules before other styles which override some bootstrap css
import './App.css'

class App extends React.Component {
  state = {
    pastedString: ''
    // parsedDataFromTsv: undefined
  }
  // state = {
  //   // layout: { title: "Reponsive plotly chart", width: 500, height: 400 }
  // }
  // const generateMouseDataBar = (keys, miceInWeek, { size = 12, min = 0, max = 200, withColors = true } = {}) =>
  //   mouseTypes.slice(0, size).map(mouseType => {
  //     const d = { mouseType }
  //     keys.forEach(key => {
  //       // NOTE keys are miceIdsInWeek
  //       d[key] = miceInWeek.find(m => m['ID'] === key && m['G+T'] === mouseType)?.AVERAGE // random(min, max)
  //       if (withColors === true) {
  //         d[`${key}Color`] = 'blue' //randColor()
  //       }
  //     })
  //     return d
  //   })

  render() {
    const { pastedString } = this.state
    const rows = tsvParse(pastedString)

    // if (!rows) return '!rows problem with pastedString'
    const gPlusT = [...new Set(rows.map(r => r['G+T']))]
    // const fallTimesAverage = rows.map(r => +r['AVERAGE'])
    // const fallTimeAverageMin = fallTimesAverage.reduce((a, b) => !isNaN(a) && !isNaN(b) && Math.min(a, b), 0) // Math.min.apply(null, fallTimesAverage) //Math.min(...fallTimesAverage)
    // const fallTimeAverageMax = fallTimesAverage.reduce((a, b) => !isNaN(a) && !isNaN(b) && Math.max(a, b), 0)
    const weeks = [...new Set(rows.map(r => r['week']))]

    const tsv = pastedString

    return (
      <div {...{ className: 'App ma3', style: {} }}>
        <h1>Chart generator</h1>
        <p>Paste spreadsheet columns and rows here, including the header row (the column labels)</p>
        <DebounceInput
          element={TextareaAutosize}
          className={''}
          style={{
            width: '100%',
            maxHeight: 200
            // width: '100%', fontFamily: 'monospace', lineHeight: 'auto'
            // backgroundColor: 'transparent'
          }}
          value={tsv}
          minLength={2}
          debounceTimeout={1000}
          placeholder={''}
          onChange={this.handlePasteScript}
        />

        <div {...{ className: '', style: {} }}>
          {weeks.map((chartWeek, i) => {
            const miceIdsInWeek = rows.filter(d => d.week === chartWeek).map(r => r['ID'])
            const miceInWeek = rows.filter(d => d.week === chartWeek)

            // console.log('fallTimesAverage', fallTimesAverage)
            // console.log('fallTimeAverageMin', fallTimeAverageMin)
            // console.log('fallTimeAverageMax', fallTimeAverageMax)
            console.log('miceIdsInWeek', miceIdsInWeek)
            console.log('miceInWeek', miceInWeek)

            const groupingLabels = [...new Set(miceInWeek.filter(m => m['G+T']).map(m => m['G+T']))].sort()
            console.log('groupingLabels', groupingLabels)

            const data = groupingLabels.map(gt => ({
              type: 'box',
              y: miceInWeek.filter(m => m['G+T'] === gt).map(m => m.AVERAGE),
              name: gt,
              jitter: 0.1, //0.3,
              pointpos: -1.5,
              marker: {
                type: 'circle',
                color: 'rgb(8,81,156)',
                size: 8
                // size: [20, 40, 20],
                // symbol: "circle-open-dot"
              },
              boxpoints: 'all',
              line: { color: 'rgba(0,0,0,0.1)' },
              fillcolor: 'rgba(0,0,0,0.05)'
            }))

            const customShapes = utils.getErrorBars({ data })

            return (
              <div {...{ key: i, className: '', style: { margin: '0.1rem', border: '1px solid white' } }}>
                <pre>gPlusT = {gPlusT.join(', ')}</pre>
                <pre>groupingLabels = {groupingLabels.join(', ')}</pre>
                <Plot
                  data={data}
                  layout={{
                    // width: 1000,
                    height: 250,
                    title: `Mice average fall time, week ${chartWeek}`,
                    yaxis: {
                      // autorange: true,
                      // showgrid: true,
                      // zeroline: true,
                      // dtick: 10,
                      gridcolor: 'rgb(255, 255, 255)',
                      gridwidth: 1,
                      zerolinecolor: 'rgb(255, 255, 255)',
                      zerolinewidth: 2
                    },
                    margin: {
                      l: 50,
                      r: 50,
                      b: 50,
                      t: 50
                    },
                    // autosize: true,
                    // autoexpand: true,
                    paper_bgcolor: 'rgb(243, 243, 243)',
                    plot_bgcolor: 'rgb(243, 243, 243)',
                    showlegend: false,
                    shapes: customShapes
                  }}
                  config={{ displayModeBar: false, responsive: true }}
                />
              </div>
            )
          })}
          <pre>rows = {JSON.stringify(rows, null, 2)}</pre>
          {/*
      <pre {...{ style: { fontSize: '1rem' } }}>MOUSE TYPES (G+T) = {JSON.stringify(mouseTypes)}</pre>
      <pre {...{ style: { fontSize: '1rem' } }}>WEEKS = {weeks.join(', ')}</pre>
      <pre {...{ style: { fontSize: '1rem' } }}>
        AVERAGE FALL TIMES MIN/MAX = {fallTimeAverageMin + ' / ' + fallTimeAverageMax}
      </pre>

      <pre {...{ style: { fontSize: '0.7rem' } }}>{JSON.stringify(data, null, 2)}</pre>
      <pre {...{ style: { fontSize: '1rem' } }}>MOUSE IN WEEK = {JSON.stringify(miceInWeek, null, 2)}</pre>

      */}
        </div>
      </div>
    )
  }
  handlePasteScript = e => {
    // console.log('e', e)
    const pastedString = e.target?.value
    this.setState({ pastedString })
  }
}

export default App
